import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`::: {.page title=“Page 1”}
::: {.layoutArea}
::: {.column}
`}<strong parentName="p">{`CROSSFIT OPEN WORKOUT 17.4`}</strong></p>
    <p><strong parentName="p"><em parentName="strong">{`Rx’d `}</em></strong>{`\\
`}<strong parentName="p">{`Complete as many rounds and reps as possible in`}</strong></p>
    <p><strong parentName="p">{`13 minutes of:`}</strong>{`\\
`}<strong parentName="p">{`55 deadlifts`}</strong></p>
    <p><strong parentName="p">{`55 wall-ball shots`}</strong></p>
    <p><strong parentName="p">{`55-calorie row`}</strong></p>
    <p><strong parentName="p">{`55 handstand push-ups`}</strong></p>
    <p>{`Men deadlift 225 lb. and throw 20-lb. ball to 10-ft. target`}</p>
    <p>{`Women deadlift 155 lb. and throw 14-lb. ball to 9-ft. target`}</p>
    <p>{`::: {.page title=“Page 2”}
::: {.layoutArea}
::: {.column}
`}<strong parentName="p"><em parentName="strong">{`Scaled`}</em>{` `}</strong>{`\\
Men deadlift 135 lb., throw 20-lb. ball to 9-ft. target and perform
hand-release push-ups\\
Women deadlift 95 lb., throw 10-lb. ball to 9-ft. target and perform
hand-release push-ups`}</p>
    <p>{`::: {.page title=“Page 2”}
::: {.layoutArea}
::: {.column}
`}<strong parentName="p"><em parentName="strong">{`Scaled Masters 55+`}</em></strong></p>
    <p>{`Men deadlift 135 lb., throw 14-lb. ball to 9-ft. target and push press
65 lb.`}</p>
    <p>{`Women deadlift 95 lb., throw 10-lb. ball to 9-ft. target and push press
45 lb.`}</p>
    <p><strong parentName="p">{`*`}{`Judging will take place during class today (as time permits) for
unlimited members only who have signed up for the Open.  We will also
have judging at The Ville from 10:00am-2:00pm on Saturday.  If you
cannot make these times please email Daniel.  We will attempt to have
some time on Sunday as well.`}</strong>{`
:::
:::
:::
:::
:::
:::
:::
:::
:::`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      